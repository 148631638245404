import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Hamburger from '../../Assets/Hamburger.png'
import Sidebarclose from '../../Assets/Sidebar_close.png'
import Sidebararrow from'../../Assets/Sidebar-arrow.png'

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <button className="close-button" onClick={toggleSidebar}>
      <img src={Sidebarclose} />
      </button>
      <ul>
        <li>
          <Link to="/Dashboard">Home <img src={Sidebararrow}/></Link>
        </li>
        <li>
          <Link to="/Calendar">Calendar <img src={Sidebararrow}/></Link>
        </li>
        <li>
          <Link to="/Transaction">Transaction <img src={Sidebararrow}/></Link>
        </li>
        <li>
          <Link to="/history">History <img src={Sidebararrow}/></Link>
        </li>
      </ul>
    </div>
  );
};


const MemberLayout = ({ children }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="member-layout">
      <Memberheader toggleSidebar={toggleSidebar} />
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className="content">{children}</div>
    </div>
  );
};
const Memberheader = ({ toggleSidebar }) => {
  return (
    <div className='navbar bg-black d-flex align-items-center justify-content-evenly'>
      <nav className='d-flex align-items-center justify-content-center'>
        <ul className='bg-black d-flex align-items-center justify-content-evenly'>
          <button className='Toggle_btn' onClick={toggleSidebar}><img src={Hamburger} /></button>
          <li>
            <Link className='text-white' to="/Dashboard">Home </Link>
          </li>
          <li>
            <Link className='text-white' to="/Calendar">Calendar </Link>
          </li>
          <li>
            <Link className='text-white' to="/Transaction">Transaction </Link>
          </li>
          <li>
            <Link className='text-white' to="/history">History </Link>
          </li>
          <button className='logout_btn text-black' type="button">Log Out</button>
        </ul>
      </nav>
    </div>
  );
};

export default MemberLayout;
