import React from 'react'
import { Link } from 'react-router-dom';
import './client_dashboard.css'
import revenue from '../../Assets/Revenue.png'
import present from '../../Assets/Present.png'
import pending_payment from '../../Assets/Pending_Payment.png'
import absent from '../../Assets/Absent.png'
import Client_header from './Client_header'
const client_dashboard = () => {
  const Client = true;

  return (

    <div>
      {Client ? <Client_header /> : <></>}
      <section ></section>
      <div className='client_dashboard position-relative'>
        <div className='revenue_present d-flex align-items-center justify-content-center '>
          <div className='revenue rounded-4 p-3 m-3 bg-white shadow-sm'>
            <div className='dashboard_image bg-primary'>
              <img src={revenue} />
            </div>
            <h3 className='fw-normal'>10000<span className='text-primary'> ₹</span></h3>
            <h4>Total Revenue</h4>
          </div>
          <div className='present rounded-4 p-3 m-3 bg-white shadow-sm'>
            <div className='dashboard_image bg-success'>
              <img src={present} />
            </div>
            <h3 className='fw-normal'>990</h3>
            <h4>Present</h4>
          </div>
        </div>
        <div className='pending_absent d-flex align-items-center justify-content-center'>
          <div className='pending rounded-4 p-3 m-3 bg-white shadow-sm'>
            <div className='dashboard_image '>
              <img src={pending_payment} />
            </div>
            <h3 className='fw-normal'>4000 <span > ₹</span></h3>
            <h4>Pending Payment</h4>
          </div>
          <div className='absent rounded-4 p-3 m-3 bg-white shadow-sm'>
            <div className='dashboard_image bg-danger'>
              <img src={absent} />
            </div>
            <h3 className='fw-normal'>10</h3>
            <h4>Absent</h4>
          </div>
        </div>
        <div className='total d-flex align-items-center justify-content-center '>
          <table className='client_table rounded-4 bg-white shadow-sm'>
            <tr>
              <td>Total cadidates </td>
              <td>:</td>
              <td>
                <Link to="/Add_candidates">
                  <button type='submit' className='viewall_btn text-white rounded-1'> View All</button>
                </Link></td>
            </tr>
            <tr>
              <td>Total one Times </td>
              <td>:</td>
              <td>60</td>
            </tr>
            <tr>
              <td>Total Two Times </td>
              <td>:</td>
              <td>100</td>
            </tr>
            <tr>
              <td>Total cadidates Absent</td>
              <td>:</td>
              <Link to="/Candidate_absent_page">
                <td> <button type='submit' className='viewall_btn text-white rounded-1'> View All</button></td>
              </Link>
            </tr>

          </table>
        </div>
      </div>
    </div>
  )
}

export default client_dashboard