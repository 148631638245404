import React from 'react'
import './History.css';

function History() {
    return (
        <div style={{ maxWidth: '1000px', height: '100%', background: '#fff' }} className='History mx-auto rounded-2'>
            <h2 >History</h2>
            <table className='history_table w-100 h-100 text-center fs-5 text'>
                <thead className='border-top border-bottom '>
                    <tr className=''>
                        <th >Hotel Name</th>
                        <th >Start Date </th>
                        <th >End Date </th>
                    </tr>
                </thead>
                <tbody >
                   <tr>
                        <td >Xyz</td>
                        <td>2 Jan 2024</td>
                        <td>2 Feb 2024</td>
                    </tr>
                    <tr>
                        <td>Xyz</td>
                        <td>2 Jan 2024</td>
                        <td>2 Feb 2024</td>
                    </tr>
                    <tr>
                        <td>Xyz</td>
                        <td>2 Jan 2024</td>
                        <td>2 Feb 2024</td>
                    </tr>
                    <tr>
                        <td>Xyz</td>
                        <td>2 Jan 2024</td>
                        <td>2 Feb 2024</td>
                    </tr>
                    <tr>
                        <td>Xyz</td>
                        <td>2 Jan 2024</td>
                        <td>2 Feb 2024</td>
                    </tr>
                    <tr>
                        <td>Xyz</td>
                        <td>2 Jan 2024</td>
                        <td>2 Feb 2024</td>
                    </tr>
                    <tr>
                        <td >Xyz</td>
                        <td>2 Jan 2024</td>
                        <td>2 Feb 2024</td>
                    </tr>
                    <tr>
                        <td>Xyz</td>
                        <td>2 Jan 2024</td>
                        <td>2 Feb 2024</td>
                    </tr>
                    <tr>
                        <td>Xyz</td>
                        <td>2 Jan 2024</td>
                        <td>2 Feb 2024</td>
                    </tr>
                    <tr>
                        <td>Xyz</td>
                        <td>2 Jan 2024</td>
                        <td>2 Feb 2024</td>
                    </tr>
                    <tr>
                        <td>Xyz</td>
                        <td>2 Jan 2024</td>
                        <td>2 Feb 2024</td>
                    </tr>
                </tbody>
            </table>
           
        </div>
    )
}
export default History;
