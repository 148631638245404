import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Hamburger from '../../Assets/Hamburger.png'
import Sidebarclose from '../../Assets/Sidebar_close.png'
import Sidebararrow from '../../Assets/Sidebar-arrow.png'

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <button className="close-button" onClick={toggleSidebar}>
        <img src={Sidebarclose} />
      </button>
      <ul>
      <li>
            <Link className='text-white' to="/client_dashboard">Home </Link>
          </li>
          <li>
            <Link className='text-white' to="/Client_transaction">Transaction </Link>
          </li>
          <li>
            <Link className='text-white' to="/add_candidates">Add Candidates </Link>
          </li>
          <li>
            <Link className='text-white' to="/Client_Update_pay">Update Payment</Link>
          </li>
          <li>
            <Link className='text-white' to="/Client_request">Requests</Link>
          </li>
      </ul>
    </div>
  );
};


const Client_header = ({ children }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="member-layout">
      <Memberheader toggleSidebar={toggleSidebar} />
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className="content">{children}</div>
    </div>
  );
};
const Memberheader = ({ toggleSidebar }) => {
  return (
    <div className='navbar bg-black d-flex align-items-center justify-content-evenly'>
      <nav className='d-flex align-items-center justify-content-center'>
        <ul className='bg-black d-flex align-items-center justify-content-evenly'>
          <button className='Toggle_btn' onClick={toggleSidebar}><img src={Hamburger} /></button>
          <li>
            <Link className='text-white' to="/client_dashboard">Home </Link>
          </li>
          <li>
            <Link className='text-white' to="/Client_transaction">Transaction </Link>
          </li>
          <li>
            <Link className='text-white' to="/add_candidates">Add Candidates </Link>
          </li>
          <li>
            <Link className='text-white' to="/Client_Update_pay">Update Payment</Link>
          </li>
          <li>
            <Link className='text-white' to="/Client_request">Requests</Link>
          </li>
          <button className='logout_btn text-black' type="button">Log Out</button>
        </ul>
      </nav>
    </div>
  );
};

export default Client_header;
