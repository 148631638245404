import React, { useState } from 'react';
import "./Client_Update_Pay.css"
import search from '../../Assets/Search.png'

const Client_Update_pay = () => {
    const initialUpdateData = [
        { id: 1, name: 'xyz', startDate: '4 sep 2023', endDate: '4 feb 2024', deposit: '₹ 2500', remaining: '₹1500' },
        { id: 2, name: 'Tushar', startDate: '4 sep 2023', endDate: '4 feb 2024', deposit: '₹ 2500', remaining: '₹1500' },
        { id: 3, name: 'Sunnny', startDate: '4 sep 2023', endDate: '4 feb 2024', deposit: '₹ 2500', remaining: '₹1500' },
        { id: 4, name: 'Atharva', startDate: '4 sep 2023', endDate: '4 feb 2024', deposit: '₹ 2500', remaining: '₹1500' },
        { id: 5, name: 'Rahul', startDate: '4 sep 2023', endDate: '4 feb 2024', deposit: '₹ 2500', remaining: '₹1500' },
        { id: 6, name: 'Appa', startDate: '4 sep 2023', endDate: '4 feb 2024', deposit: '₹ 2500', remaining: '₹1500' },
        { id: 7, name: 'Ritika', startDate: '4 sep 2023', endDate: '4 feb 2024', deposit: '₹ 2500', remaining: '₹1500' },
        { id: 8, name: 'Vaishnavi', startDate: '4 sep 2023', endDate: '4 feb 2024', deposit: '₹ 2500', remaining: '₹1500' },
        { id: 9, name: 'Krushna', startDate: '4 sep 2023', endDate: '4 feb 2024', deposit: '₹ 2500', remaining: '₹1500' },
        { id: 10, name: 'Komal', startDate: '4 sep 2023', endDate: '4 feb 2024', deposit: '₹ 2500', remaining: '₹1500' },

    ];

    const [updateData, setUpdateData] = useState(initialUpdateData);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredData, setFilteredData] = useState([]);

    const rowsPerPage = 10;

    const handleSearch = (e) => {
        e.preventDefault();
        const filteredResults = initialUpdateData.filter((data) =>
            data.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredData(filteredResults);
        setCurrentPage(1);
    };

    const indexOfLastUpdate = currentPage * rowsPerPage;
    const indexOfFirstUpdate = indexOfLastUpdate - rowsPerPage;
    const currentUpdate = (filteredData.length > 0 ? filteredData : updateData).slice(indexOfFirstUpdate, indexOfLastUpdate);

    const totalPages = Math.ceil((filteredData.length > 0 ? filteredData : initialUpdateData).length / rowsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const renderNumberPagination = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <span
                    key={i}
                    className={`page-number ${currentPage === i ? 'active' : ''}`}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </span>
            );
        }
        return pageNumbers;
    };

    return (
        <>
            <div className='Form_container'>
                <form onSubmit={handleSearch} className="search-bar">
                    <input
                        type="text"
                        placeholder="Search"
                        name="q"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <button type="submit" className='border-0 bg-white rounded-5'>
                        <img src={search} alt="Search" className='search-img ' style={{ height: '30px' }} />
                    </button>
                </form>
            </div>

            <div className="update-container">
                <table className="update-table">
                    <thead>
                        <tr>
                            <th>Sr.No</th>
                            <th>Name</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Deposit<br /> (Amount)</th>
                            <th>Remaining<br /> (Amount)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentUpdate.map((data, index) => (
                            <tr key={data.id} className={index % 2 === 0 ? 'even' : 'odd'}>
                                <td>{index + 1}</td>
                                <td>{data.name}</td>
                                <td>{data.startDate}</td>
                                <td>{data.endDate}</td>
                                <td>{data.deposit}</td>
                                <td>{data.remaining}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="update-pagination-numbers">{renderNumberPagination()}</div>
            </div>
        </>
    );
};

export default Client_Update_pay;
