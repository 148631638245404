import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import image1 from "../../Assets/img01.jpeg"
import image2 from "../../Assets/img02.jpeg"
import image3 from "../../Assets/img03.jpeg"
import Facebook from "../../Assets/Facebbok.png"
import Google from "../../Assets/Google.png"
import MessLogo from "../../Assets/MessLogo.png"
import "../../App.css"
import "../Home/Home.css";
import "../Home/Home_respo.css"

const Login = () => {
    const [action, setAction] = useState("Register");

    const [token, setToken] = useState(null);
    const [error, setError] = useState(null);
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');


    const handleLogin = async () => {
        try {
            const response = await fetch('http://localhost:8080/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Response:', response);

                console.log("The data is =", data);

                const newToken = data.token;

                // Store the token (localStorage, Redux)
                setToken(newToken);

                // Redirect to dashboard or update the UI as needed


            } else {
                const errorData = await response.json();
                setError(errorData.message || 'Login failed');
            }
        } catch (error) {
            console.error('Error during login:++++++++', error);
            setError('An error occurred during login*******');
        }
    };

    return (
        <>
            <div className='nav z-3 top-0 sticky-top text-white'>
                <img src={MessLogo} alt='' />
            </div>

            <div className='loginpage d-flex align-items-center justify-content-start'>
                <div className='carousal'>
                    <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={image1} className="d-block" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={image2} className="d-block" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={image3} className="d-block" alt="..." />
                            </div>
                            <div className="carousel-overlay">
                                {/* Login */}
                                {action === "Register" ? <div></div> :
                                    <from>
                                        <h2 className='overlayForm fw-bold'>Log in </h2>
                                        <div className='login_radio_btn w-100 '>
                                            <h6>login as</h6>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                <label class="form-check-label" for="flexRadioDefault1">
                                                    Hotel owner
                                                </label>
                                            </div>

                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                                <label class="form-check-label" for="flexRadioDefault2">
                                                    Member
                                                </label>
                                            </div>
                                        </div>

                                        <div class="mb-3">
                                            <label for="exampleInputEmail1" class="form-label">Email</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Email' value={username} onChange={(e) => setUserName(e.target.value)} />
                                        </div>

                                        <div class="mb-3">
                                            <label for="exampleInputPassword1" class="form-label">Password</label>
                                            <input type="password" class="form-control" id="exampleInputPassword1" placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} />
                                        </div>
                                        <div className="mb-3 form-check">
                                            <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                            <label className="form-check-label" htmlFor="exampleCheck1">Remember Password</label>
                                        </div>
                                        <span><a href='/'>Forgot Password ?</a></span>
                                        <div className="mb-3">
                                            <Link to="Dashboard">
                                                <button type='submit' className='btn login-btn w-100 fs-4'>Log in</button>
                                            </Link>
                                        </div>
                                        <p class="text-center">Or</p>
                                        <div className="mb-3">
                                            <button type='submit' className='btn btn-light facebook-btn w-100'>
                                                <img src={Facebook} className="d-block" alt="Facebook" />
                                                <a className='text-dark fs-6' href='https://www.facebook.com/login/'>Log in With Facebook</a>
                                            </button>
                                        </div>
                                        <div className="mb-3">
                                            <button type='submit' className='btn btn-light google-btn w-100'>
                                                <img src={Google} className="d-block" alt="Google" />
                                                <a className='text-dark fs-6' href='https://www.google.com'>Log in With Google</a>
                                            </button>
                                        </div>
                                        <div className={action === "Register" ? "Submit gray" : "submit"} onClick={() => { setAction("Register") }}>
                                            Don’t have an account?
                                            <span><a onClick={() => { setAction("Login") }}> Create account</a></span>
                                        </div>
                                    </from>
                                }

                                {/* Register */}
                                {action === "Login" ? <div></div> :
                                    <from>
                                        <h2 className='overlayForm fw-bold'>{action} </h2>
                                        <div className='login_radio_btn w-100'>
                                            <h6>Register as</h6>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                <label class="form-check-label" for="flexRadioDefault1">
                                                    Hotel owner
                                                </label>
                                            </div>

                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                                <label class="form-check-label" for="flexRadioDefault2">
                                                    Member
                                                </label>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail2" className="form-label">Full Name</label>
                                            <input type="text" className="form-control" id="exampleInputText" placeholder='Full Name' />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail2" className="form-label">Contact No</label>
                                            <input type="phone" className="form-control" id="exampleInputText" placeholder='Contact No' />
                                        </div>

                                        <div class="mb-3">
                                            <label for="exampleInputEmail1" class="form-label">Email</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Email' value={username} onChange={(e) => setUserName(e.target.value)} />
                                        </div>

                                        <div class="mb-3">
                                            <label for="exampleInputPassword1" class="form-label">Password</label>
                                            <input type="password" class="form-control" id="exampleInputPassword1" placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputPassword3" className="form-label">Confirm Password</label>
                                            <input type="password" className="form-control" id="exampleInputPassword3" placeholder='Confirm Password' />
                                        </div>
                                        <button type="submit" className="btn  w-100" onClick={handleLogin}>
                                            <div className="mb-3">
                                                <button type='submit' className='btn login-btn w-100 fs-4'>Register</button>
                                            </div>
                                        </button>
                                        <div className={action === "Login" ? "Submit gray" : "submit"} onClick={() => { setAction("Login") }}>
                                            Already Have an Account ?
                                            <span><a > login</a></span>
                                        </div>
                                    </from>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <form controlId="loginForm" className="login-form p-4 rounded-4 bg-white">

                    {/* Login */}
                    {action === "Register" ? <div></div> :
                        <from>
                            <h2 className='fw-bold'>Log in to account </h2>
                            <h6>Register as</h6>
                            <div className='login_radio_btn d-flex w-100'>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        Hotel owner
                                    </label>
                                </div>

                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                    <label class="form-check-label" for="flexRadioDefault2">
                                        Member
                                    </label>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Email</label>
                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Email' value={username} onChange={(e) => setUserName(e.target.value)} />
                            </div>

                            <div class="mb-3">
                                <label for="exampleInputPassword1" class="form-label">Password</label>
                                <input type="password" class="form-control" id="exampleInputPassword1" placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <div className="mb-3 form-check">
                                <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                <label className="form-check-label" htmlFor="exampleCheck1">Remember Password</label>
                            </div>
                            <span><a href='/'>Forgot Password ?</a></span>
                            <div className="mb-3">
                                <Link to="Dashboard">
                                    <button type='submit' className='btn login-btn w-100 fs-4'>Log in</button>
                                </Link>
                            </div>
                            <p class="text-center">Or</p>
                            <div className="mb-3">
                                <button type='submit' className='btn btn-light facebook-btn w-100 d-flex gap-5'>
                                    <img src={Facebook} className="d-block" alt="Facebook" />
                                    <a className='text-dark fs-6' href='https://www.facebook.com/login/'>Log in With Facebook</a>
                                </button>
                            </div>
                            <div className="mb-3">
                                <button type='submit' className='btn btn-light google-btn w-100 d-flex gap-5'>
                                    <img src={Google} className="d-block" alt="Google" />
                                    <a className='text-dark fs-6' href='https://www.google.com'>Log in With Google</a>
                                </button>
                            </div>
                            <div className={action === "Register" ? "Submit gray" : "submit"} onClick={() => { setAction("Register") }}>
                                Don’t have an account?
                                <span><a onClick={() => { setAction("Login") }}> Create account</a></span>
                            </div>
                        </from>
                    }

                    {/* Register */}
                    {action === "Login" ? <div></div> :
                        <from>
                            <h2 className='fw-bold'>{action} account as</h2>
                            <div className='login_radio_btn d-flex'>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        Hotel owner
                                    </label>
                                </div>

                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                    <label class="form-check-label" for="flexRadioDefault2">
                                        Member
                                    </label>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail2" className="form-label">Full Name</label>
                                <input type="text" className="form-control" id="exampleInputText" placeholder='Full Name' />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail2" className="form-label">Contact No</label>
                                <input type="phone" className="form-control" id="exampleInputText" placeholder='Contact No' />
                            </div>

                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Email</label>
                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Email' value={username} onChange={(e) => setUserName(e.target.value)} />
                            </div>

                            <div class="mb-3">
                                <label for="exampleInputPassword1" class="form-label">Password</label>
                                <input type="password" class="form-control" id="exampleInputPassword1" placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputPassword3" className="form-label">Confirm Password</label>
                                <input type="password" className="form-control" id="exampleInputPassword3" placeholder='Confirm Password' />
                            </div>
                            <button type="submit" className="btn  w-100" onClick={handleLogin}>
                                <div className="mb-3">
                                    <button type='submit' className='btn login-btn w-100 fs-4'>Register</button>
                                </div>
                            </button>
                            <div className={action === "Login" ? "Submit gray" : "submit"} onClick={() => { setAction("Login") }}>
                                Already Have an Account ?
                                <span><a > login</a></span>
                            </div>
                        </from>
                    }
                </form>

            </div>

        </>
    )
}

export default Login