import React, { useState } from 'react';
import search from '../../Assets/Search.png'
import '../Client/Candidate_absent_page.css'

const Candidate_absent_page = () => {
    const absentData = [
        { id: 1, name: 'xyz', absent_days: '25' },
        { id: 2, name: 'Tushar', absent_days: '5' },
        { id: 3, name: 'sunny', absent_days: '15' },
        { id: 4, name: 'Atharva', absent_days: '15' },
        { id: 5, name: 'Appa', absent_days: '0' },
        { id: 6, name: 'Rahul', absent_days: '15' },
        { id: 7, name: 'Ritika', absent_days: '0' },
        { id: 8, name: 'vaishnavi', absent_days: '0' },
        { id: 9, name: 'Monika', absent_days: '0' },
        { id: 10, name: 'krushna', absent_days: '0' },

    ];

   
    const rowsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    const filteredData = absentData.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const indexOfLastData = currentPage * rowsPerPage;
    const indexOfFirstData = indexOfLastData - rowsPerPage;
    const currentData = filteredData.slice(indexOfFirstData, indexOfLastData);

    const totalPages = Math.ceil(filteredData.length / rowsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleSearch = (event) => {
        event.preventDefault();
        setCurrentPage(1);
    };

    const renderNumberPagination = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <span
                    key={i}
                    className={`page-number ${currentPage === i ? 'active' : ''}`}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </span>
            );
        }
        return pageNumbers;
    };

    return (
        <div>
            <div className='Form_container'>
                <form onSubmit={handleSearch} className="search-bar">
                    <input
                        type="text"
                        placeholder="Search"
                        name="q"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <button type="submit" className='border-0 bg-white rounded-5'>
                        <img src={search} alt="Search" className='search-img 'style={{ height:'30px'}} />
                    </button>
                </form>
            </div>
            <div className="absentdata-container">
                <table className="absentdata-table">
                    <h2 className='text-start'>Absent Candidates</h2>
                    <table>
                    <thead>
                        <tr>
                            <th>Sr.No</th>
                            <th>Name</th>
                            <th>Number Of Absent Days</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentData.map((absentData, index) => (
                            <tr key={absentData.id} className={index % 2 === 0 ? 'even' : 'odd'}>
                                <td>{index + 1}</td>
                                <td>{absentData.name}</td>
                                <td>{absentData.absent_days}</td>
                            </tr>
                        ))}
                    </tbody>
                    </table>
                </table>
                <div className="pagination-numbers">{renderNumberPagination()}</div>
            </div>
        </div>
    );
};

export default Candidate_absent_page