import React from 'react'

import FooterFacebook from "../../Assets/facebook01.png"
import FooterInstagram from "../../Assets/Instagram.png"
import Footerwhatsapp from "../../Assets/Whatsapp.png"
import MessLogo from "../../Assets/MessLogo.png"
import '../Home/Home.css'
import '../Home/Home_respo.css'
const Footer = () => {
    return (
        <>
            <div className='main_foot w-100'>
                <div className='footer d-flex align-items-center justify-content-evenly text-start'>
                    <div className='Footer_about'>
                        <img src={MessLogo}/>
                        <h3>About</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Id officia doloremque quidem incidunt veniam maxime dolorem modi doloribus. Dignissimos assumenda repudiandae soluta culpa alias laudantium corporis, vero distinctio tempore exercitationem.
                        </p>
                    </div>
                    <div className='Footer_contact'>
                        <div className='social_links'>
                            <h3>Email</h3>
                            <p>Mess@123.gmail.com</p>
                            <h3>Quick Links</h3>
                            <p>PrivacyPolicy</p>
                        </div>
                        <div className='socials'>
                            <img src={FooterFacebook} alt='' />
                            <img src={FooterInstagram} alt='' />
                            <img src={Footerwhatsapp} alt='' />
                        </div>
                    </div>
                </div>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </div>
        </>
    )
}

export default Footer