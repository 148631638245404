import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Editprofile.css'
import "./Dashboard.css"
import './Dashboard_respo.css'
import user_logo from '../../Assets/MessUser_Logo.png'
import calendar from '../../Assets/Calendar.png'
import call from '../../Assets/call.png'
import message from '../../Assets/message.png'
import location from '../../Assets/location.png'
import downarrow from '../../Assets/Down_arrow.png'
import edit_profile from '../../Assets/edit_profile.png'
import Header from './Header.js'
import Client_header from '../Client/Client_header';
const Dashboard = () => {
  const [readMore, setReadMore] = useState(false);

  const [imageSrc, setImageSrc] = useState('#');
  const [isPopupOpen, setPopupOpen] = useState(false);


  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = function (e) {
        setImageSrc(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const [isOtpPopupOpen, setOtpPopupOpen] = useState(false);
  const togglePopup = () => {
    setOtpPopupOpen(!isOtpPopupOpen);
  };

  const [isEmailPopupOpen, setEmailPopupOpen] = useState(false);
  const toggleEmailPopup = () => {
    setEmailPopupOpen(!isEmailPopupOpen);
  };
  const Member = true;
  const [otp, setOTP] = useState('');
  const [generatedOTP, setGeneratedOTP] = useState('');
  const [otpStatus, setOTPStatus] = useState('');

  const generateOTP = () => {
    const newOTP = Math.floor(100000 + Math.random() * 900000);

    alert(`Generated OTP: ${newOTP}`);

    setGeneratedOTP(newOTP.toString());
  };

  const validateOTP = () => {
    if (otp === generatedOTP) {
      setOTPStatus('OTP is valid. Access granted.');
    } else {
      setOTPStatus('Invalid OTP. Please try again.');
    }
  };
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationStatus, setVerificationStatus] = useState('');

  const sendVerificationEmail = () => {

    alert(`Verification email sent to ${email}`);
  };

  const verifyEmail = () => {

    if (verificationCode === '123456') {
      setVerificationStatus('Email verified. Access granted.');
    } else {
      setVerificationStatus('Invalid verification code. Please try again.');
    }
  };
  const [mobileNumber, setMobileNumber] = useState('');
  const handleOtpInputChange = (e, index) => {
    const newOtp = otp.split('');
    newOtp[index] = e.target.value;
    setOTP(newOtp.join(''));
  };

  return (
    <>
      {Member ? < Header /> : < Client_header />}
      <div className="main">
        <Link to="/Calendar">
          <div className='calendar_btn'><button className='rounded-3 bg-white float-end'><img src={calendar} />Calendar</button></div>
        </Link>
        <div className='welcome_text'>
          <h2>Hello Sunny !</h2>
          <p>Welcome to your Dashboard hope you are doing good</p>
        </div>

        <div className='main_profile d-flex align-items-center justify-content-center '>
          <div className="card-container">
            <div className="upper-container">
              <div className='edit_profile position-absolute'>
                <img src={edit_profile} onClick={openPopup} />
              </div>
              {isPopupOpen && (
                <div className="popup d-flex align-items-center justify-content-center">
                  <div className="overlay bg-white">
                    <span className="close" onClick={closePopup}>&times;</span>
                    <div className="main-container">
                      <h2>Edit Profile</h2>
                      <div className="popup_container d-flex justify-content-center">

                        <div className="uploadimg d-flex align-items-center justify-content-center flex-column">
                          <h4>Change Profile</h4>

                          <div className="image-preview">
                            <img id="uploadedImage" src={imageSrc} alt="Uploaded Image" />
                          </div>

                          <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileChange} />
                          <button id="uploadButton" onClick={() => document.getElementById('fileInput').click()}>Upload New Photo</button>
                        </div>

                        <div className="name-profile">
                          <label htmlFor="fname">First Name</label><br />
                          <input type="text" id="fname" /><br />

                          <label htmlFor="lname">Last Name</label><br />
                          <input type="text" id="lname" /><br />

                          <label htmlFor="mobileNo">Mobile No.</label><br />
                          <input type="tel" id="mobileNo" /><br />

                          <label htmlFor="Address">Address</label><br />
                          <div className="address">
                            <div>
                              <input type="text" id="area" placeholder="Area" /><br /><br />
                              <input type="text" id="taluka" placeholder="Taluka" /><br /><br />
                            </div>
                            <div>
                              <input type="text" id="district" placeholder="District" /><br /><br />
                              <input type="text" id="pincode" placeholder="Pincode" /><br /><br />
                            </div>
                          </div>

                          <select id="State">
                            <option value="state"> State </option>
                            <option value="Maharashtra"> Maharashtra </option>
                            <option value="Uttar Pradesh"> Uttar Pradesh </option>
                            <option value="Rajasthan"> Rajasthan </option>
                            <option value="Bihar"> Bihar </option>
                          </select>

                          <div className='save_btn'>
                            <button type="submit"> Save Changes </button>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="image-container">
                <img src={user_logo} alt="User Logo" />
              </div>
            </div>
            <div className="lower-container ">
              <h3>Sunny Salve</h3>
              <div className="Contact  d-flex align-items-center text-start">
                <img src={call} alt="Call Icon" />
                <h5 className="fw-light">1234567892</h5>
                <button className='change_btn rounded-5' onClick={togglePopup}>Change</button>
                {isOtpPopupOpen && (
                  <div className="Otppopup">
                    <div className="Otppopup-content">
                      <div>
                        <h2>Mobile number</h2>
                        <p>Please Enter Your Number</p>

                        <form>
                          <input
                            type="tel"
                            className='OtpMobileInput rounded-1 border-1'
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                            placeholder="Enter Mobile Number"
                            required
                          />
                          <button type="button" onClick={generateOTP}>Generate OTP</button>
                          <br /><br />
                          <div className="otp-input-container">
                            {[...Array(6)].map((_, index) => (
                              <input className='OtpInput rounded-1 border-1'
                                type="text"
                                key={index}
                                value={otp.charAt(index) || ''}
                                onChange={(e) => handleOtpInputChange(e, index)}
                                maxLength="1"
                                required
                              />
                            ))}
                          </div>
                          <br /><br />

                          <button type="button" onClick={validateOTP}>Verify OTP</button>
                        </form>

                        <p>{otpStatus}</p>
                      </div>
                      <button onClick={togglePopup}>Close</button>
                    </div>
                  </div>
                )}
              </div>
              <div className="Email  d-flex align-items-center text-start">
                <img src={message} alt="Message Icon" />
                <h5 className="fw-light">xyz@gmail.com</h5>
                <button className='change_btn rounded-5' onClick={toggleEmailPopup}>Change</button>
                {isEmailPopupOpen && (
                  <div className="Emailpopup">
                    <div className="Emailpopup-content">

                      <h2>Email Verification</h2>
                      <p>Enter your email and the verification code sent to your email.</p>

                      <form>
                        <label>Email:</label>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" required />
                        <br /><br />

                        <button type="button" onClick={sendVerificationEmail}>Send Verification Email</button>
                        <br /><br />

                        <label>Verification Code:</label>
                        <input type="text" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} placeholder="Enter verification code" required />
                        <br /><br />

                        <button type="button" onClick={verifyEmail}>Verify Email</button>
                      </form>

                      <p style={{ marginTop: '20px', color: verificationStatus.includes('invalid') ? 'red' : 'green' }}>{verificationStatus}</p>

                      <button className='Email_CLose' onClick={toggleEmailPopup}>Close</button>
                    </div>
                  </div>
                )}
              </div>
              <div className="Location  d-flex align-items-center text-start">
                <img src={location} alt="Location Icon" />
                <h5 className="fw-light"> xyz,abc,NO12</h5>
              </div>
            </div>
          </div>

          <div className="card-container read_more " >
            <div className="Second_upper_container">
              <div className="image-container">
              </div>
            </div>
            <div className="second-lower-container">
              <table>
                <thead>
                  <tr>
                    <td>Amount</td>
                    <td className='px-4'> : 2500</td>
                  </tr>
                  <tr>
                    <td>Type</td>
                    <td className='px-4'>: One/ Two-Time</td>
                  </tr>
                  <tr>
                    <td>Month</td>
                    <td className='px-4'>: Jan 2024</td>
                  </tr>
                  <tr>
                    <td>Present</td>
                    <td className='px-4'>:<button className='profile_present text-white rounded-5'>10 </button> </td>
                  </tr>
                  <tr>
                    <td>Absent</td>
                    <td className='px-4'>: <button className='profile_absent text-white rounded-5'>5 </button></td>
                  </tr>
                  <tr>
                    <td>Half</td>
                    <td className='px-4'>:<button className='profile_half text-white rounded-5'>11 </button></td>
                  </tr>
                  <tr>
                    <td>Remaining Days</td>
                    <td className='px-4'>:<button className='profile_Remaining text-white rounded-5' >4 </button></td>
                  </tr>
                  <tr>
                    <td>Start Date</td>
                    <td className='px-4'>: 2 Jan 2024</td>
                  </tr>
                  <tr>
                    <td>End Date</td>
                    <td className='px-4'>: 2 Feb 2024</td>
                  </tr>
                  <tr>
                    <td>Pending Payment</td>
                    <td className='fw-bold px-4'>: 500</td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>

          <input id="read-more-check-1" type="checkbox" className="read-more-check" checked={readMore} onChange={() => setReadMore(!readMore)} />
          <label htmlFor="read-more-check-1" className="read-more-label"><img className='the_pic' src={downarrow} /></label>

          <div className={`card-container read-more ${readMore ? 'expanded' : ''}`}>

            <div className="second-lower-container">
              <table className='d-flex align-items-center justify-content-center text-start'>
                <thead>
                  <tr>
                    <td>Amount</td>
                    <td> : 2500</td>
                  </tr>
                  <tr>
                    <td>Type</td>
                    <td>: One/ Two-Time</td>
                  </tr>
                  <tr>
                    <td>Month</td>
                    <td>: Jan 2024</td>
                  </tr>
                  <tr>
                    <td>Present</td>
                    <td>:<button className='profile_present'>10 </button> </td>
                  </tr>
                  <tr>
                    <td>Absent</td>
                    <td>: <button className='profile_absent'>5 </button></td>
                  </tr>
                  <tr>
                    <td>Half</td>
                    <td>:<button className='profile_half'>15 </button></td>
                  </tr>
                  <tr>
                    <td>Pending Days</td>
                    <td className='px-4'>:<button className='profile_Remaining text-white rounded-5' >4 </button></td>
                  </tr>
                  <tr>
                    <td>Start Date</td>
                    <td>: 2 Jan 2024</td>
                  </tr>
                  <tr>
                    <td>End Date</td>
                    <td>: 2 Feb 2024</td>
                  </tr>
                  <tr>
                    <td className='fw-bold px-1'>Pending Payment</td>
                    <td> :<span className='fw-bold px-4'>500/-</span></td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
        <div className='btn_group'>
          <Link to="/Transaction">
            <div className='Transaction'><button>Transaction</button></div>
          </Link>
          <Link to="/client_dashboard">
            <div className='Transaction'><button>client_dashboard</button></div>
          </Link>
        </div>
      </div >
    </>
  )
}

export default Dashboard