import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Components/Home/Login';
import Dashboard from './Components/Member/Dashboard.js';
import Footer from './Components/Home/Footer.js';
import Calendar from './Components/Member/Calendar.js'
import Transaction from './Components/Member/Transaction.js'
import History from './Components/Member/History.js'
import Client_dashboard from './Components/Client/Client_dash_board.js'
import Client_transaction from './Components/Client/Client_transaction.js';
import Add_candidates from './Components/Client/Add_candidates.js';
import Client_Update_pay from './Components/Client/Client_Update_pay.js'
import Client_request from './Components/Client/Client_request.js';
import Candidate_absent_page from './Components/Client/Candidate_absent_page.js'

const App = () => {

  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/history" element={<History />} />
        <Route path="/transaction" element={<Transaction />} />
        <Route path="/transaction" element={<Transaction />} />
        <Route path="/client_dashboard" element={<Client_dashboard />} />
        <Route path="/client_transaction" element={<Client_transaction />} />
        <Route path="/add_candidates" element={<Add_candidates />} />
        <Route path="/Client_Update_pay" element={<Client_Update_pay />} />
        <Route path="/Client_request" element={<Client_request />} />
        <Route path="/Candidate_absent_page" element={<Candidate_absent_page />} />



      </Routes>
      <Footer />
    </>
  );
}

export default App;
