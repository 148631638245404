import React, { useState } from 'react';
import './Transaction.css'
import './Transaction_respo.css'

const Transaction = () => {
  const transactions = [
    { id: 1, month: 'Jan 2023', amount: 2500, status: 'Paid' },
    { id: 2, month: 'Feb 2023', amount: 2500, status: 'Paid' },
    { id: 3, month: 'March 2023', amount: 2500, status: 'Paid' },
    { id: 4, month: 'april 2023', amount: 2500, status: 'Paid' },
    { id: 5, month: 'May 2023', amount: 2500, status: 'Paid' },
    { id: 6, month: 'June 2023', amount: 2500, status: 'Paid' },
    { id: 7, month: 'July 2023', amount: 2500, status: 'Paid' },
    { id: 8, month: 'Aug 2023', amount: 2500, status: 'Paid' },
    { id: 9, month: 'Sep 2023', amount: 2500, status: 'Paid' },
    { id: 10, month: 'Oct 2023', amount: 200, status: 'Pending' },
    { id: 11, month: 'Nov 2023', amount: 2500, status: 'Paid' },
    { id: 12, month: 'Dec 2023', amount: 500, status: 'Pending' },
    { id: 13, month: 'Jan 2024', amount: 2500, status: 'Paid' },
    { id: 14, month: 'Feb 2024', amount: 300, status: 'Pending' },
    { id: 15, month: 'March 2024', amount: 2500, status: 'Paid' },
    { id: 16, month: 'April 2024', amount: 2500, status: 'Paid' },

  ];

  const rowsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastTransaction = currentPage * rowsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - rowsPerPage;
  const currentTransactions = transactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

  const totalPages = Math.ceil(transactions.length / rowsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const renderNumberPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <span
          key={i}
          className={`page-number ${currentPage === i ? 'active' : ''}`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </span>
      );
    }
    return pageNumbers;
  };
  return (
    <div>
      <div className="transaction-container">
        <table className="transaction-table">
          <h2 className='text-start'>Transaction</h2>
          <table>
            <thead>
              <tr>
                <th>Sr. No</th>
                <th>Month</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {currentTransactions.map((transaction, index) => (
                <tr key={transaction.id} className={index % 2 === 0 ? 'even' : 'odd'}>
                  <td>{index + 1}</td>
                  <td>{transaction.month}</td>
                  <td style={{ color: transaction.status === 'Pending' ? 'red' : 'green' }}>
                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'INR' }).format(transaction.amount)}
                  </td>
                  <td style={{ color: transaction.status === 'Pending' ? 'red' : 'green' }}>{transaction.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </table>
        <div className="pagination-numbers">{renderNumberPagination()}</div>
      </div>
    </div>

  );
};

export default Transaction;
