import React, { useState } from 'react';
import './Client_request.css'

const Client_request = () => {

    const [transactions] = useState([
        { id: 1, name: 'Tushar R. Hanwate', date: '2 - 7 - 2023', duration: 'One Time', type: 'Afternoon', status: 'Paid' },
        { id: 2, name: 'xyz', date: '2 - 7 - 2023', duration: 'One Time', type: 'Afternoon', status: 'Paid' },
        { id: 3, name: 'xyz', date: '2 - 7 - 2023', duration: 'Two Time', type: 'Afternoon, Evening', status: 'Paid' },
        { id: 4, name: 'xyz', date: '2 - 8 - 2023', duration: 'Two Time', type: 'Afternoon, Evening', status: 'Pending' },
        { id: 5, name: 'xyz', date: '2 - 8 - 2023', duration: 'Two Time', type: 'Afternoon, Evening', status: 'Pending' },
        { id: 6, name: 'xyz', date: '2 - 8 - 2023', duration: 'One Time', type: 'Afternoon', status: 'Paid' },
        { id: 7, name: 'xyz', date: '2 - 8 - 2023', duration: 'One Time', type: 'Afternoon, Evening', status: 'Paid' },
        { id: 8, name: 'xyz', date: '2 - 8 - 2024', duration: 'Two Time', type: 'Afternoon, Evening', status: 'Paid' },
        { id: 9, name: 'xyz', date: '2 - 9 - 2024', duration: 'Two Time', type: 'Afternoon, Evening', status: 'Pending' },
        { id: 10, name: 'xyz', date: '2 - 9 - 2024', duration: 'Two Time', type: 'Afternoon, Evening', status: 'Pending' },
        { id: 11, name: 'xyz', date: '2 - 9 - 2024', duration: 'One Time', type: 'Afternoon', status: 'Paid' },
        { id: 12, name: 'xyz', date: '2 - 9 - 2024', duration: 'Two Time', type: 'Afternoon, Evening', status: 'Pending' },
        { id: 13, name: 'xyz', date: '2 - 9 - 2024', duration: 'One Time', type: 'Afternoon', status: 'Paid' },
        { id: 14, name: 'xyz', date: '2 - 1 - 2024', duration: 'Two Time', type: 'Afternoon, Evening', status: 'Paid' },
        { id: 15, name: 'xyz', date: '2 - 1 - 2024', duration: 'One Time', type: 'Afternoon', status: 'Pending' },
        { id: 16, name: 'xyz', date: '2 - 1 - 2024', duration: 'Two Time', type: 'Afternoon, Evening', status: 'Pending' },
        
    ]);

    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;
    const totalPages = Math.ceil(transactions.length / rowsPerPage);

    const indexOfLastTransaction = currentPage * rowsPerPage;
    const indexOfFirstTransaction = indexOfLastTransaction - rowsPerPage;
    const currentTransactions = transactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

    const [showPopup, setShowPopup] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);

    const togglePopup = (transaction) => {
        setSelectedTransaction(transaction);
        setShowPopup(true);
    };

    const handleApprove = () => {
        if (selectedTransaction) {
            selectedTransaction.status = 'Paid';
            setShowPopup(false);
        }
    };

    const handleReject = () => {
        setShowPopup(false);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const renderNumberPagination = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <span
                    key={i}
                    className={`page-number ${currentPage === i ? 'active' : ''}`}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </span>
            );
        }
        return pageNumbers;
    };

    return (
        <div>
            <div className="request-container">
                <table className="request-table">
                    <h2 className='text-start'>Requests</h2>
                    <table className="request-table">
                        <thead>
                            <tr>
                                <th>Sr.No</th>
                                <th>Name</th>
                                <th>Date</th>
                                <th>Duration</th>
                                <th>Type</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentTransactions.map((transaction, index) => (
                                <tr
                                    key={transaction.id}
                                    className={index % 2 === 0 ? 'even' : 'odd'}
                                    onClick={() => togglePopup(transaction)}
                                >
                                    <td>{index + 1}</td>
                                    <td>{transaction.name}</td>
                                    <td>{transaction.date}</td>
                                    <td>{transaction.duration}</td>
                                    <td>{transaction.type}</td>
                                    <td style={{ color: transaction.status === 'Pending' ? 'red' : 'green' }}>{transaction.status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </table>
                <div className="pagination-numbers">{renderNumberPagination()}</div>
            </div>
            {showPopup && (
                <div className="request_popup position-absolute rounded-4 bg-white">
                    <div className="request-popup-content text-center">
                        <p>Confirmation to Pending Request</p>
                        <div className='approval_btns py-2 px-5'>
                            <button className='bg-success border-0 rounded-5 mx-3 px-3 text-white' onClick={handleApprove}>
                                Approve
                            </button>
                            <button className='bg-danger border-0 rounded-5 mx-3 px-3 text-white' onClick={handleReject}>
                                Reject
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Client_request


