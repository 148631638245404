
import React, { useState, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import './Calendar.css';
const Calendar = () => {
  const [date, setDate] = useState(new Date());
  const [scale, setScale] = useState(1);
  const [presentPopupOpen, setPresentPopupOpen] = useState(false);
  const [presentPopupSection, setPresentPopupSection] = useState(1);
  const [absentPopupOpen, setAbsentPopupOpen] = useState(false);
  const [absentPopupSection, setAbsentPopupSection] = useState(1);
  const [halfPopupOpen, setHalfPopupOpen] = useState(false);
  const [halfPopupSection, setHalfPopupSection] = useState(1);
  const [remainingPopupOpen, setRemainingPopupOpen] = useState(false);
  const [selectedMealType, setSelectedMealType] = useState('');
  const [remainingPopupSection, setRemainingPopupSection] = useState(1);
  const [selectedDate, setSelectedDate] = useState(null);
  const [presentCount, setPresentCount] = useState(0);
  const [greenButtonCount, setGreenButtonCount] = useState(0);
  const [RedButtonCount, setRedButtonCount] = useState(0);
  const [OrangeredButtonCount, setOrangeredButtonCount] = useState(0);
  const [GeryButtonCount, setGeryButtonCount] = useState(0);
  const [lunchSelected, setLunchSelected] = useState(false);
  const [dinnerSelected, setDinnerSelected] = useState(false);

  const [selectedDateColor, setSelectedDateColor] = useState('');


  const attendanceData = [
    {
      "DATE": "2024-02-01",
      "MEALTYPE": "DINNER",
      "ATTENDANCE": "PRESENT"
    },

    {
      "DATE": "2024-02-01",
      "MEALTYPE": "LUNCH",
      "ATTENDANCE": "PRESENT"
    },
    {
      "DATE": "2024-02-02",
      "MEALTYPE": "LUNCH",
      "ATTENDANCE": "ABSENT"
    },
    {
      "DATE": "2024-02-02",
      "MEALTYPE": "DINNER",
      "ATTENDANCE": "PRESENT"
    },
    {
      "DATE": "2024-02-03",
      "MEALTYPE": "DINNER",
      "ATTENDANCE": "ABSENT"
    },
    {
      "DATE": "2024-02-03",
      "MEALTYPE": "LUNCH",
      "ATTENDANCE": "ABSENT"
    },
    {
      "DATE": "2024-02-04",
      "MEALTYPE": "LUNCH",
      "ATTENDANCE": "PRESENT"
    },
    {
      "DATE": "2024-02-04",
      "MEALTYPE": "DINNER",
      "ATTENDANCE": "ABSENT"
    },
    {
      "DATE": "2024-02-05",
      "MEALTYPE": "DINNER",
      "ATTENDANCE": "ABSENT"
    },
    {
      "DATE": "2024-02-05",
      "MEALTYPE": "LUNCH",
      "ATTENDANCE": "PRESENT"
    },
    {
      "DATE": "2024-02-06",
      "MEALTYPE": "DINNER",
      "ATTENDANCE": "ABSENT"
    },
    {
      "DATE": "2024-02-06",
      "MEALTYPE": "LUNCH",
      "ATTENDANCE": "PRESENT"
    },
    {
      "DATE": "2024-02-07",
      "MEALTYPE": "DINNER",
      "ATTENDANCE": "PRESENT"
    },

    {
      "DATE": "2024-02-07",
      "MEALTYPE": "LUNCH",
      "ATTENDANCE": "PRESENT"
    },
    {
      "DATE": "2024-02-08",
      "MEALTYPE": "LUNCH",
      "ATTENDANCE": "PRESENT"
    },
    {
      "DATE": "2024-02-08",
      "MEALTYPE": "DINNER",
      "ATTENDANCE": "PRESENT"
    },
    {
      "DATE": "2024-02-09",
      "MEALTYPE": "DINNER",
      "ATTENDANCE": "PRESENT"
    },
    {
      "DATE": "2024-02-09",
      "MEALTYPE": "LUNCH",
      "ATTENDANCE": "PRESENT"
    }
  ];
  useEffect(() => {
    countGreenButtons();
    countRedButtons();
    countOrangeredButtons();
    countGeryButtons();
  }, [date]);

  const countGreenButtons = () => {
    const greenButtonElements = document.querySelectorAll('.present');
    setGreenButtonCount(greenButtonElements.length);
  };
  const countRedButtons = () => {
    const RedButtonElements = document.querySelectorAll('.absent');
    setRedButtonCount(RedButtonElements.length);
  };
  const countOrangeredButtons = () => {
    const OrangeredButtonElements = document.querySelectorAll('.half');
    setOrangeredButtonCount(OrangeredButtonElements.length);
  };
  const countGeryButtons = () => {
    const GeryButtonElements = document.querySelectorAll('.remaining');
    setGeryButtonCount(GeryButtonElements.length);
  };

  const goToPreviousMonth = () => {
    const previousMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    setDate(previousMonth);
  };

  const goToNextMonth = () => {
    const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    setDate(nextMonth);
  };

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const daysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const firstDayOfMonth = () => {
    return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  };
  const renderCalendar = () => {
    const firstDay = firstDayOfMonth();
    const totalDays = daysInMonth(date.getFullYear(), date.getMonth());
    const blanks = [];

    for (let i = 0; i < firstDay; i++) {
      blanks.push(<td key={i * 80} className="calendar-day empty">{""}</td>);
    }

    const daysInMonthArray = [];
    for (let d = 1; d <= totalDays; d++) {
      const attendanceDataForDate = attendanceData.filter(item => {
        const itemDate = new Date(item.DATE);
        return itemDate.getDate() === d && itemDate.getMonth() === date.getMonth() && itemDate.getFullYear() === date.getFullYear();
      });

      let className = "calendar-day";
      let lunchAttendance = null;
      let dinnerAttendance = null;


      attendanceDataForDate.forEach(item => {
        if (item.MEALTYPE === "LUNCH") {
          lunchAttendance = item.ATTENDANCE;
        } else if (item.MEALTYPE === "DINNER") {
          dinnerAttendance = item.ATTENDANCE;
        }
      });


      if (lunchAttendance === "PRESENT" && dinnerAttendance === "PRESENT") {
        className += " present";
      } else if (lunchAttendance === "ABSENT" && dinnerAttendance === "ABSENT") {
        className += " absent";
      } else if ((lunchAttendance === "ABSENT" && dinnerAttendance === "PRESENT") || (lunchAttendance === "PRESENT" && dinnerAttendance === "ABSENT")) {
        className += " half";
      } else {
        className += " remaining";
      }

      daysInMonthArray.push(
        <td key={d} className={className} >
          <div onClick={() => handleCellClick(d, lunchAttendance, dinnerAttendance)} className='card data_box m-auto align-baseline text-center  p-sm-1 p-md-3 p-lg-3' style={{ maxWidth: '60px' }}>{d}</div>
        </td>
      );
    }

    const totalSlots = [...blanks, ...daysInMonthArray];
    let rows = [];
    let cells = [];

    totalSlots.forEach((day, i) => {
      if (i % 7 !== 0) {
        cells.push(day);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(day);
      }
      if (i === totalSlots.length - 1) {
        rows.push(cells);
      }
    });

    return rows.map((row, i) => {
      return (
        <tr key={i}>{row}</tr>
      );
    });
  };

  const handleCellClick = (day, lunchAttendance, dinnerAttendance) => {
    if (lunchAttendance === "PRESENT" && dinnerAttendance === "PRESENT") {
      setPresentPopupOpen(true);
    } else if (lunchAttendance === "ABSENT" && dinnerAttendance === "ABSENT") {
      setAbsentPopupOpen(true);
    } else if ((lunchAttendance === "ABSENT" && dinnerAttendance === "PRESENT") || (lunchAttendance === "PRESENT" && dinnerAttendance === "ABSENT")) {
      setHalfPopupOpen(true);
    } else if (lunchAttendance === null && dinnerAttendance === null) {
      setSelectedDate(`${date.getFullYear()}-${date.getMonth() + 1}-${day}`);

      setRemainingPopupOpen(true);
    }
  };

  const closePopups = () => {
    setPresentPopupOpen(false);
    setPresentPopupSection(1);

    setAbsentPopupOpen(false);
    setAbsentPopupSection(1);

    setHalfPopupOpen(false);
    setHalfPopupSection(1);

    setRemainingPopupOpen(false);
    setRemainingPopupSection(1);

  };
  const openNextPresentPopupSection = (nextSection) => {
    setPresentPopupSection(nextSection);
  };
  const openNextAbsentPopupSection = (section) => {
    setAbsentPopupSection(section);
  };
  const openNextHalfPopupSection = (section) => {
    setHalfPopupSection(section);
  };
  const openNextremainingPopupSection = (section) => {
    setRemainingPopupSection(section);
  };
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };


  const handleOK = (mealType) => {
    const dateParts = selectedDate.split('-');
    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1;
    const day = parseInt(dateParts[2]);
    if (mealType === 'Lunch') {
      setSelectedDateColor('orange');
    } else if (mealType === 'Dinner') {
      setSelectedDateColor('orange');
    }
    setRemainingPopupOpen(false);
  };

  const handleCancel = () => {
    setRemainingPopupOpen(false);
  };

  const handlePresentClick = () => {
    const presentCells = document.querySelectorAll('.present');
    presentCells.forEach(cell => {
      cell.classList.add('hard-hit-animation');
    });


    setTimeout(() => {
      presentCells.forEach(cell => {
        cell.classList.remove('hard-hit-animation');
      });
    }, 100);
  };

  const handleAbsentClick = () => {
    const absentCells = document.querySelectorAll('.absent');
    absentCells.forEach(cell => {
      cell.classList.add('hard-hit-animation');
    });

    setTimeout(() => {
      absentCells.forEach(cell => {
        cell.classList.remove('hard-hit-animation');
      });
    }, 100);
  };

  const handlehalfClick = () => {
    const halfCells = document.querySelectorAll('.half');
    halfCells.forEach(cell => {
      cell.classList.add('hard-hit-animation');
    });
    setTimeout(() => {
      halfCells.forEach(cell => {
        cell.classList.remove('hard-hit-animation');
      });
    }, 100);
  };
  const handleRemainingClick = () => {
    const remainingCells = document.querySelectorAll('.remaining');
    remainingCells.forEach(cell => {
      cell.classList.add('hard-hit-animation');
    });

    setTimeout(() => {
      remainingCells.forEach(cell => {
        cell.classList.remove('hard-hit-animation');
      });
    }, 100);
  };
  const handleAfternoonClick = () => {
    setSelectedMealType('Lunch');
    setRemainingPopupOpen(true);
  };

  const handleEveningClick = () => {
    setSelectedMealType('Dinner');
    setRemainingPopupOpen(true);
  };


  return (
    <div className='main-calendar ' style={{ padding: '40px' }}>
      <div>
        <div className="calendar-container mx-auto  position-relative " style={{ borderRadius: '20px', background: '#fff' }}>
          <div style={{ background: '#000', color: '#fff', boxShadow: '1px 10px 10px #524f4fd8', borderRadius: '10px' }} className='Calendar-controll-btn d-grid position-absolute top-0 start-100 translate-middle p-1 '>
            <div className=' p-1  d-inline-flex align-self-center gap-2' >
              <button className='Present-btn border-0 rounded-circle p-sm-2 p-md-2' style={{ backgroundColor: '#34A853' }} onClick={handlePresentClick} ></button><div className='calendar-container-text d-flex align-self-center '>
                <table>
                  <tr className='mx-50'>
                    <td>Present</td>
                    <td className='px-1'>:</td>
                    <td className='px-3'>{greenButtonCount}</td>
                  </tr>
                </table>
                </div>
            </div>
            <div className='p-1  d-inline-flex align-self-center gap-2'>
              <button className='Absent-btn border-0 rounded-circle   p-sm-2 p-md-2' style={{ backgroundColor: '#FE1400' }} onClick={handleAbsentClick}></button><div className='calendar-container-text  d-flex align-self-center '>
              <table>
                  <tr>
                    <td>Absent</td> 
                    <td className='px-1'>:</td>
                    <td className='px-3'>{RedButtonCount}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div className='p-1  d-inline-flex align-self-center  gap-2'>
              <button className='Half-btn border-0 rounded-circle p-sm-2 p-md-2' style={{ backgroundColor: '#F46413' }} onClick={handlehalfClick}></button> <div className='calendar-container-text   d-flex align-self-center '>
              <table>
                  <tr>
                    <td>Half</td>
                    <td className='px-3'>:</td>
                    <td className='px-1'>{OrangeredButtonCount}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div className='p-1  d-inline-flex align-self-center   gap-2'>
              <button className='remaining-btn border-0 rounded-circle p-sm-2 p-md-2' style={{ backgroundColor: '#A09A9A' }} onClick={handleRemainingClick}></button> <div className='calendar-container-text  d-flex align-self-center '> 
              <table>
                  <tr>
                    <td>Remaining</td>
                    <td className='px-1'>:</td>
                    <td className='px-1'>{GeryButtonCount}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div>
            <div className='main-heading d-flex top-0 start-0 align-items-center justify-content-between w-75' style={{ width: '900px' }}>
              <h3 className='heading'>Calendar</h3>
              <button className='btn Apply-Leave-btn  rounded  p-sm-1  p-md-2' onClick={togglePopup} style={{ backgroundColor: '#F46413', color: '#fff' }} >Apply Leave</button>
            </div>
            <div className="calendar-navigation mt-4 ... ms-2 ... d-flex  align-items-baseline fw-semibold">
              <button className=' btn month-btn border-0 rounded p-1 p-sm-1 px-md-1' style={{ backgroundColor: '#F46413', color: '#fff' }} >Month</button>
              <button className='btn  fs-6 pre-btn border-0' onClick={goToPreviousMonth} style={{ backgroundColor: 'transparent' }}>&lt;</button>
              <p className='calendar-btn'>{months[date.getMonth()]} {date.getFullYear()}</p>
              <button className='btn fs-6 next-btn border-0' onClick={goToNextMonth} style={{ backgroundColor: 'transparent' }}>&gt;</button>
            </div>

            <table className="profile_calender " >
              <thead className="text-center">
                <tr className="" >
                  <th>SUN</th>
                  <th>MON</th>
                  <th>TUE</th>
                  <th>WED</th>
                  <th>THU</th>
                  <th>FRI</th>
                  <th>SAT</th>
                </tr>
              </thead>
              <tbody className='present-clicked'>
                {renderCalendar()}
              </tbody>
            </table>
          </div>
        </div>
        {isOpen && (
          <div className="div position-fixed   top-50 start-50 translate-middle w-100 h-100" style={{ backgroundColor: 'rgba(0, 0, 0, 0.185)', }}>
            <div className="popup-content popup position-absolute top-50 start-50 translate-middle  p-5 rounded-3" style={{ backgroundColor: '#fff', boxShadow: '1px 1px 10px #0000009a' }} >
              <div className='popup-box-itemsd-flex d-flex justify-content-start text-start gx-5 p-2'>
                <label className='fs-5 fw-semibold'>Date : </label><input type='date' className='btn p-0' />

              </div>
              <div className='popup-box-itemsd-flex d-flex justify-content-start text-start  align-self-center gap-4 p-2'>
                <label for="option1" className='fs-5 fw-semibold'>Lunch :</label>
                <input type="radio" id="option1" name="fav_language" value="option1" />
              </div>
              <div className='popup-box-itemsd-flex d-flex justify-content-start text-start  align-self-center gap-3 p-2'>
                <label for="option2" className='fs-5 fw-semibold'>Dinner :</label>
                <input type="radio" id="option2" name="fav_language" value="option2" />
              </div >
              <button onClick={togglePopup} style={{ backgroundColor: '#F46413', color: '#fff' }} className='btn float-end' >Save</button>
            </div>
          </div>
        )}
        <div>

          {presentPopupOpen && (
            <div className="popup-container " >
              <div className="Present-popup-content position-fixed  w-100vh h-100  top-0 start-0" style={{ background: '#0000006a', width: '100%', height: '100%' }}>
                {presentPopupSection == 1 && (
                  <div className='d-flex flex-wrap  gap-3  justify-content-evenly position-absolute  top-50 start-50 translate-middle' >
                    <div className='text-center ' style={{ background: '#34A853', color: '#fff', borderRadius: '20px', padding: '50px', boxShadow: '1px 1px 10px #0000009a' }}>
                      <h1>Afternoon</h1>
                      <button className='rounded-circle p-2 border-0 text-success' onClick={() => openNextPresentPopupSection(2)}><KeyboardArrowDownIcon /></button>
                    </div>
                    <div className='text-center ' style={{ background: '#34A853', color: '#fff', borderRadius: '20px', padding: '50px 70px', boxShadow: '1px 1px 10px #0000009a' }}>
                      <h1>Evening</h1>
                      <button className='rounded-circle p-2 border-0 text-success' onClick={() => openNextPresentPopupSection(2)}><KeyboardArrowDownIcon /></button>
                    </div>
                  </div>
                )}
                {presentPopupSection === 2 && (
                  <div onClick={closePopups} className='position-fixed  w-100vh h-100  top-0 start-0' style={{ width: '100%', height: '100%' }}>
                    <div className=' position-absolute top-50 start-50 translate-middle text-center text-light fs-5 p-3 rounded-3' style={{ background: '#34A853', boxShadow: '1px 1px 10px #0000009a' }}>
                      <p className='fs-2'>This day has been marked
                        Present Day</p>
                    </div>

                  </div>
                )}


              </div>
            </div>
          )}

          {absentPopupOpen && (
            <div className="popup-container" >
              <div className="Present-popup-content position-fixed  w-100vh h-100  top-0 start-0" style={{ background: '#0000006a', width: '100%', height: '100%' }}>
                {absentPopupSection === 1 && (
                  <div className='d-flex flex-wrap gap-3  justify-content-evenly  position-absolute top-50 start-50 translate-middle'>
                    <div className='text-center ' style={{ background: '#FE1400', color: '#fff', borderRadius: '20px', padding: '50px', boxShadow: '1px 1px 10px #0000009a' }}>
                      <h1>Afternoon</h1>
                      <button className='rounded-circle p-2 border-0 text-success' onClick={() => openNextAbsentPopupSection(2)}><KeyboardArrowDownIcon /></button>
                    </div>
                    <div className='text-center ' style={{ background: '#FE1400', color: '#fff', borderRadius: '20px', padding: '50px 70px', boxShadow: '1px 1px 10px #0000009a' }}>
                      <h1>Evening</h1>
                      <button className='rounded-circle p-2 border-0 text-success' onClick={() => openNextAbsentPopupSection(2)}><KeyboardArrowDownIcon /></button>
                    </div>
                  </div>
                )}

                {absentPopupSection === 2 && (
                  <div onClick={closePopups} className='position-fixed  w-100vh h-100  top-0 start-0' style={{ width: '100%', height: '100%' }}>
                    <div className='position-absolute top-50 start-50 translate-middle text-center text-light fs-5 p-3 rounded-3' style={{ background: '#FE1400', boxShadow: '1px 1px 10px #0000009a', color: '#fff' }}>

                      <p className='fs-2 '>This day has been marked
                        Absent Day</p>
                    </div>

                  </div>
                )}
              </div>
            </div>
          )}
          {halfPopupOpen && (
            <div className="popup-container">
              <div className="Present-popup-content position-fixed  w-100vh h-100  top-0 start-0" style={{ background: '#0000006a', width: '100%', height: '100%' }}>
                {halfPopupSection === 1 && (
                  <div className='d-flex flex-wrap  gap-3  justify-content-evenly position-absolute position-absolute top-50 start-50 translate-middle'>
                    <div className='text-center ' style={{ background: '#34A853', color: '#fff', borderRadius: '20px', padding: '50px', boxShadow: '1px 1px 10px #0000009a' }}>
                      <h1>Afternoon</h1>
                      <button className='rounded-circle p-2 border-0 text-success' onClick={() => openNextHalfPopupSection(2)}><KeyboardArrowDownIcon /></button>
                    </div>
                    <div className='text-center ' style={{ background: '#FE1400', color: '#fff', borderRadius: '20px', padding: '50px 70px', boxShadow: '1px 1px 10px #0000009a' }}>
                      <h1>Evening</h1>
                      <button className='rounded-circle p-2 border-0 text-success' onClick={() => openNextHalfPopupSection(2)}><KeyboardArrowDownIcon /></button>
                    </div>
                  </div>
                )}

                {halfPopupSection === 2 && (
                  <div onClick={closePopups} className='position-fixed  w-100vh h-100  top-0 start-0' style={{ width: '100%', height: '100%' }}>
                    <div className='position-absolute top-50 start-50 translate-middle text-center text-light fs-5 p-3  rounded-3' style={{ background: '#F46413', boxShadow: '1px 1px 10px #0000009a', color: '#fff' }}>
                      <p className='fs-2 '>This day has been marked
                        Half Day</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {remainingPopupOpen && (
          <div>
            {remainingPopupSection === 1 && (
              <div className='d-flex flex-wrap gap-3 justify-content-evenly position-absolute top-50 start-50 translate-middle'>
                <div className='text-center' style={{ background: '#34A853', color: '#fff', borderRadius: '20px', padding: '50px', boxShadow: '1px 1px 10px #0000009a' }}>
                  <h1>Afternoon</h1>
                  <button className='rounded-circle p-2 border-0 text-success' onClick={() => openNextremainingPopupSection(2)}><KeyboardArrowDownIcon /></button>
                </div>
                <div className='text-center' style={{ background: '#34A853', color: '#fff', borderRadius: '20px', padding: '50px 70px', boxShadow: '1px 1px 10px #0000009a' }}>
                  <h1>Evening</h1>
                  <button className='rounded-circle p-2 border-0 text-success' onClick={() => openNextremainingPopupSection(3)}><KeyboardArrowDownIcon /></button>
                </div>
              </div>
            )}

            {remainingPopupSection === 2 && (
              <div className="popup-container">
                <div className="Present-popup-content position-fixed w-100vh h-100 top-0 start-0" style={{ background: '#0000005a', width: '100%', height: '100%' }}>
                  <div className='position-fixed w-100vh h-100 top-0 start-50'>
                    <div className='position-absolute top-50 start-50 translate-middle text-center p-5 p-sm-1 px-md-5 rounded-3 ' style={{ background: '#fff', boxShadow: '1px 1px 10px #0000009a', width: '300px' }}>
                      <p className='fs-4'>Would you like to apply Lunch leave on {selectedDate}</p>
                      <div className="mt-3 d-flex justify-content-around">
                        <button className="btn btn-light" style={{ background: '#F46413', color: '#fff' }} onClick={handleOK}>OK</button>
                        <button className="btn me-3" style={{ background: '#000', color: '#fff' }} onClick={handleCancel}>Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {remainingPopupSection === 3 && (
              <div className="popup-container">
                <div className="Present-popup-content position-fixed w-100vh h-100 top-0 start-0" style={{ background: '#0000005a', width: '100%', height: '100%' }}>
                  <div className='position-fixed w-100vh h-100 top-0 start-50'>
                    <div className='position-absolute top-50 start-50 translate-middle text-center p-5 p-sm-1 px-md-5 rounded-3' style={{ background: '#fff', width: '300px', boxShadow: '1px 1px 10px #0000009a' }}>
                      <p className='fs-4'>Would you like to apply Dinner leave on {selectedDate}</p>
                      <div className="mt-3 d-flex justify-content-around">
                        <button className="btn btn-light" style={{ background: '#F46413', color: '#fff' }} onClick={handleOK}>OK</button>
                        <button className="btn me-3" style={{ background: '#000', color: '#fff' }} onClick={handleCancel}>Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          </div>
        )}
      </div>
    </div>
  );
};

export default Calendar;