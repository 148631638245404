import React, { useState } from 'react';
import '../Member/Transaction.css';
import '../Member/Transaction_respo.css';
import '../Client/add_candidates.css'
import '../../App.css'
import close_btn from '../../Assets/close.png'
import search from '../../Assets/Search.png'

const Add_candidates = () => {
    const [candidates, setCandidates] = useState([
        { id: 1, name: 'xyz', startDate: 'jan 2023', endDate: 'mar 2023', status: 'Approved' },
        { id: 2, name: 'Tushar', startDate: 'feb 2023', endDate: 'mar 2023', status: 'Approved' },
        { id: 3, name: 'sunny', startDate: 'feb 2023', endDate: 'apr 2023', status: 'Approved' },
        { id: 4, name: 'Atharva', startDate: 'feb 2023', endDate: 'may 2023', status: 'Approved' },
        { id: 5, name: 'Appa', startDate: 'mar 2023', endDate: 'apr 2023', status: 'Pending' },
        { id: 6, name: 'Rahul', startDate: 'mar 2023', endDate: 'apr 2023', status: 'Pending' },
        { id: 5, name: 'Appa', startDate: 'mar 2023', endDate: 'apr 2023', status: 'Pending' },
        { id: 6, name: 'Rahul', startDate: 'mar 2023', endDate: 'apr 2023', status: 'Pending' },
        { id: 5, name: 'Appa', startDate: 'mar 2023', endDate: 'apr 2023', status: 'Pending' },
        { id: 6, name: 'Rahul', startDate: 'mar 2023', endDate: 'apr 2023', status: 'Pending' },
        { id: 5, name: 'Appa', startDate: 'mar 2023', endDate: 'apr 2023', status: 'Pending' },
        { id: 6, name: 'Rahul', startDate: 'mar 2023', endDate: 'apr 2023', status: 'Pending' },
        
    ]);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = useState({ name: '', startDate: '', endDate: '', status: '' });

    const rowsPerPage = 10;

    const indexOfLastCandidate = currentPage * rowsPerPage;
    const indexOfFirstCandidate = indexOfLastCandidate - rowsPerPage;
    const currentCandidate = candidates.slice(indexOfFirstCandidate, indexOfLastCandidate);

    const totalPages = Math.ceil(candidates.length / rowsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleSearch = (event) => {
        event.preventDefault();
        const filteredCandidate = candidates.filter((candidate) =>
            candidate.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setCandidates(filteredCandidate);
        setCurrentPage(1);
    };

    const renderNumberPagination = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <span
                    key={i}
                    className={`page-number ${currentPage === i ? 'active' : ''}`}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </span>
            );
        }
        return pageNumbers;
    };

    const handleShowForm = () => {
        setShowForm(true);
    };
    const handleCloseForm = () => {
        setShowForm(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFormSubmit = () => {
        const newCandidate = { id: candidates.length + 1, ...formData };
        setCandidates([...candidates, newCandidate]);
        setShowForm(false);
        setFormData({ name: '', startDate: '', endDate: '', status: '' });
    };

    return (
        <div>
            <div className='Form_container'>
                <form onSubmit={handleSearch} className="search-bar">
                    <input
                        type="text"
                        placeholder="Search"
                        name="q"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <button type="submit" className='border-0 bg-white rounded-5'>
                        <img src={search} alt="Search" className='search-img ' style={{ height: '30px' }} />
                    </button>
                </form>
            </div>
            <div className="candidate-container">
                <table className="transaction-table">
                    <thead>
                        <button className='Add_candidate_btn rounded-2' onClick={handleShowForm}>Add Candidates</button>
                        <tr>
                            <th>Sr. No</th>
                            <th>Name</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentCandidate.map((candidate, index) => (
                            <tr key={candidate.id} className={index % 2 === 0 ? 'even' : 'odd'}>
                                <td>{index + 1}</td>
                                <td>{candidate.name}</td>
                                <td>{candidate.startDate}</td>
                                <td>{candidate.endDate}</td>
                                <td style={{ color: candidate.status === 'Pending' ? 'red' : 'green' }}>
                                    {candidate.status}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="pagination-numbers">{renderNumberPagination()}</div>
            </div>
            {showForm && (
                <div className="popup-overlay position-fixed m-auto d-flex align-items-center justify-content-center">
                    <form className="popup-form bg-white rounded-3">
                        <h3>Add Candidates</h3>
                        <p>Enter your Details Below</p>
                        <img className='close_btn' src={close_btn} onClick={handleCloseForm} />
                        <label>
                            <input type="text" name="name" placeholder='Name' value={formData.name} onChange={handleInputChange} />
                        </label>
                        <div className='email_phone d-flex'>
                            <label>
                                <input type="email" name="status" placeholder='Email' />
                            </label>
                            <label>
                                <input type="phone" name="status" placeholder='Mobile No' />
                            </label>
                        </div>
                        <label>
                            <input type="text" name="status" placeholder='Address' />
                        </label>

                        <div className='start_end_date d-flex flex-column'>
                            <label className='text-start text-muted'>
                                StartDate
                                <input type="date" name="startDate" placeholder='Start Date' value={formData.startDate} onChange={handleInputChange} />
                            </label>
                            <label className='text-start text-muted'>
                                End Date
                                <input type="date" name="endDate" placeholder='End Date' value={formData.endDate} onChange={handleInputChange} />
                            </label>
                        </div>

                        <label>
                            <input type="text" name="status" placeholder='Payment' value={formData.status} onChange={handleInputChange} />
                        </label>
                        <label>
                            <input type="date" name="status" placeholder='Joining Date' />
                        </label>

                        <div className='add_close_btn d-flex justify-content-between'>
                            <button className='add_btn  m-auto d-flex align-items-center justify-content-center ' type="button" onClick={handleFormSubmit}>
                                Save & close
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export default Add_candidates;